import React from "react";

import Button from "../components/button"; 

import Layout from "../components/layout/layout";


const NotFoundPage = () => (
  <Layout metadata={ { title: "株式会社テストサービス", is404: true } }>
    <main class="page--404 page">
      <h2 className="heading-mega">404</h2>
      <h3 className="heading-secondary">もう一度確認お願いします。</h3>
      <p class="copy--404">存在しないルーターに接続しようとしています。</p>
      <div class="u-margin-top-small"></div>
      <Button buttonClassName="button button--primary" route="/">ホームページに戻って下さい。</Button>
      <div class="u-margin-bottom-medium"></div>
    </main>
  </Layout>
);

export default NotFoundPage;
