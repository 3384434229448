import React from 'react';
import { Link } from 'gatsby';

const button = ({ route, buttonClassName, children }) => {
    return (
        <Link className={ buttonClassName } to={ route }>{ children }</Link>
    )
}

export default button;
